import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { Stack, Main } from "@layout"
import PageTitle from "../components/Shared/PageTitle"
import Divider from "@components/Divider"
import Seo from "@widgets/Seo"
import CardList from "../components/Blog/CardList"
import TextList from "@components/TextList"

import "./animation.css"

const styles = {
  text: {
    fontSize: `1rem`,
    textAlign: `left`,
  },
}

const ServicesQuery = graphql`
  query ServicesMarkdowns {
    servicesData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "services" } } }
    ) {
      nodes {
        id
        frontmatter {
          title
          excerpt
        }
        fields {
          slug
        }
      }
    }
  }
`

export default function () {
  const { servicesData } = useStaticQuery(ServicesQuery)

  const services = servicesData.nodes.map(dataItem => {
    const {
      id,
      frontmatter: { excerpt, title },
      fields: { slug },
    } = dataItem

    return {
      slug,
      excerpt,
      title,
      id,
    }
  })

  const text = [
    `Data Collaborative is the world’s leading provider of Quick Base
            services. We understand how business works, from sole
            proprietorships to Fortune 500 companies.`,
    `Whether it’s a brand new application or an extension of an existing
            application, we are the experts. We understand that every
            implementation require analyzing requirements, configuring the
            database, customizing elements to fit specific business needs, QA
            testing, and tweaking. We can provide all, or any one of these
            services that meet your needs.`,
    `Need help integrating with QuickBooks or other business systems? 
            Need to port legacy applications to Quick Base? Does your field 
            team need to sync their mobile devices with your database? We can 
            help you do all these things, and more.`,
    `Give us a call at 781-777-1119, x1 and find out how quickly your data world can change.`,
  ]

  return (
    <>
      <Seo title="Services" />
      <Divider space={3} />
      <div className="animateInDown">
        <PageTitle header="Services" mb={0} />
      </div>

      <div className="animateInDown">
        <TextList separator="">
          {text.map((item, idx) => (
            <p key={`p-${idx}`} style={styles.text}>
              {item}
            </p>
          ))}
        </TextList>
      </div>
      <Divider space={2} />
      <div className="animateInUp">
        <Main style={{ marginBottom: "35px" }}>
          {services.length > 0 ? (
            <CardList
              nodes={services}
              variant={["vertical"]}
              columns={[1, 2, 3, 3]}
            />
          ) : (
            "There are no services"
          )}
        </Main>
      </div>
    </>
  )
}
